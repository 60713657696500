

@import "./support/support";
@import "./custom/setup";
@import "./color_schemes/light";

@import "./modules";
div.opaque {
  background-color: $body-background-color;
}p.highlightPurple, blockquote.highlightPurple {
    background: rgba($purple-000, 0.2);
    border-left: $border-radius solid $purple-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlightPurple-title {
      color: $purple-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlightPurple-title, blockquote.highlightPurple-title {
    background: rgba($purple-000, 0.2);
    border-left: $border-radius solid $purple-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $purple-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlightPurple {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlightPurple-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.highlightGray, blockquote.highlightGray {
    background: rgba($grey-lt-000, 0.2);
    border-left: $border-radius solid $grey-lt-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlightGray-title {
      color: $grey-lt-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlightGray-title, blockquote.highlightGray-title {
    background: rgba($grey-lt-000, 0.2);
    border-left: $border-radius solid $grey-lt-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $grey-lt-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlightGray {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlightGray-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.highlightRed, blockquote.highlightRed {
    background: rgba($red-000, 0.2);
    border-left: $border-radius solid $red-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlightRed-title {
      color: $red-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlightRed-title, blockquote.highlightRed-title {
    background: rgba($red-000, 0.2);
    border-left: $border-radius solid $red-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $red-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlightRed {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlightRed-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.highlightGreen, blockquote.highlightGreen {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlightGreen-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlightGreen-title, blockquote.highlightGreen-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlightGreen {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlightGreen-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.highlightYellow, blockquote.highlightYellow {
    background: rgba($yellow-000, 0.2);
    border-left: $border-radius solid $yellow-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlightYellow-title {
      color: $yellow-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlightYellow-title, blockquote.highlightYellow-title {
    background: rgba($yellow-000, 0.2);
    border-left: $border-radius solid $yellow-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $yellow-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlightYellow {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlightYellow-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.highlightBlue, blockquote.highlightBlue {
    background: rgba($blue-000, 0.2);
    border-left: $border-radius solid $blue-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    > .highlightBlue-title {
      color: $blue-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.highlightBlue-title, blockquote.highlightBlue-title {
    background: rgba($blue-000, 0.2);
    border-left: $border-radius solid $blue-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $blue-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.highlightBlue {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.highlightBlue-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.important, blockquote.important {
    background: rgba($blue-000, 0.2);
    border-left: $border-radius solid $blue-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $blue-300;
        content: "Important";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .important-title {
      color: $blue-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.important-title, blockquote.important-title {
    background: rgba($blue-000, 0.2);
    border-left: $border-radius solid $blue-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $blue-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.important {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.important-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.new, blockquote.new {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $green-300;
        content: "New";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .new-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.new-title, blockquote.new-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.new {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.new-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.note, blockquote.note {
    background: rgba($purple-000, 0.2);
    border-left: $border-radius solid $purple-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $purple-300;
        content: "Note";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .note-title {
      color: $purple-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.note-title, blockquote.note-title {
    background: rgba($purple-000, 0.2);
    border-left: $border-radius solid $purple-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $purple-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.note {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.note-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.warning, blockquote.warning {
    background: rgba($red-000, 0.2);
    border-left: $border-radius solid $red-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $red-300;
        content: "Warning";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .warning-title {
      color: $red-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.warning-title, blockquote.warning-title {
    background: rgba($red-000, 0.2);
    border-left: $border-radius solid $red-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $red-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.warning {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.warning-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}


@import "./custom/custom";


